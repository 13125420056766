.graph_container {
    width: 1600px;
    height: 500px;
    background-color: #FFFFFF;
    padding: 15px;
    margin-bottom: 20px;
    padding-bottom: 50px;
}

.graph_container .dygraph-title {
    text-align: center;
}

@media (max-width: 1650px) {
    .graph_container {
        width: 1400px;
    }
}

@media (max-width: 1200px) {
    .graph_container {
        width: 1000px;
    }
}