.listHeader {
    height: 100px;
    position: relative;
    width: 100%;
    padding: 10px;
}

.listHeaderButton {
    float: right;
    margin-top: 10px;
    margin-right: 5px;
}

.nodeContent {
    align-items: center;
    height: 50%;
    display: flex;
    justify-content: center;
}

.nodeListContainer {
    text-align: center;
    width: 100%;
    border: solid 1px #e6e6e6;
    background-color: #FAFAFA;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.selected {
    background-color: #5e7ecc54;
}

.selected:hover {
    background-color: rgba(17, 38, 162, 0.5);

}

.nodeList {
    padding: 0px;
}

.bottomRigthFixedButton {
    z-index: 999999;
    position: fixed;
    bottom: 5%;
    right: 40px;
}

.dataExplorerButton:hover {
    box-shadow: 0 0 0 0 rgba(84, 101, 191, 0.7);
    background-color: #5465bf;

    -webkit-animation: miniPulse 1.25s infinite cubic-bezier(0.46, 0, 0, 1);
    -moz-animation: miniPulse 1.25s infinite cubic-bezier(0.46, 0, 0, 1);
    -ms-animation: miniPulse 1.25s infinite cubic-bezier(0.46, 0, 0, 1);
    animation: miniPulse 1.25s infinite cubic-bezier(0.46, 0, 0, 1);
}

.dataExplorerButton {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;

}

.LoadTable {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 5%;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /*height: 440px;*/
}

@-webkit-keyframes miniPulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}

@-moz-keyframes miniPulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}

@-ms-keyframes miniPulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}

@keyframes miniPulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}
