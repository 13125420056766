.MuiPaper-root {
    background-color: #212121;
}

.display-table-cell {
    display: table-cell;
    float: none;
    min-height: 100%;
    position: relative;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.drawerLogo {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.content_sidebar {
    position: fixed;
    padding: 10px;
    width: 16%;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#navigation {
    height: auto;
    background: #212121;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    min-width: 250px;
}

.naviLogo {
    padding: 20px;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
}

.navButton button {
    min-width: 40px !important;
    text-align: left;
}

.navi li, ul {
    list-style: none;
}

.navi {
    position: fixed;
}

.navigationListadmin {
    position: relative;
}

.navButton button {
    min-width: 40px !important;
    text-align: left;
    color: #e3eded !important;
    text-transform: none;
}


.navigation_bottom {
    bottom: 20px;
    left: 0px;
    position: absolute;
}

.help{
    bottom: 60px;
}

