.passwordButtonsContainer {
    display: flex;
    justify-content: center;
    margin: 0px auto;
}

.passwordButtonsContainer button {
    margin: 10px;
}

.passwordCheckBox {
    display: flex;
    justify-content: center;
    margin: 15px auto;
}

.passwordCheckBox span {
    color: whitesmoke;
}

.passwordCheckBox input {
    background-color: #6c8cb7!important;
    border-color: #6c8cb7!important;
}
