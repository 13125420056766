* {
    margin: 0px;
    padding: 0px;
}

.swal2-popup {
    font-size: 1.6rem !important;
}

.body {
    heigth: 100%;
    font-family: Roboto sans-serif
}

.leaflet-container {
    margin: 0;
    height: 100vh;
    width: 100%;
}

.leaflet-container, .loading, #gradient {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}

.slider {
    width: 70%;
    background: white;
    border-radius: 5px 5px 5px;
    padding: 10px;
    margin: 10px;
}

._1yb8L._2LZ7Z {
    color: #3b68f8 !important;
    opacity: 0.8;
}

._1yb8L {
    color: white !important;

}

._1yb8L._2gi1s {
    color: white !important;
    opacity: 0.25 !important;
}

.graphPanelContainer {
    z-index: 9999;
    top: 80px;
    left: 15px;
    border-radius: 10px;
    position: fixed;
    background-color: rgba(2, 11, 30, 0.9);

    padding: 10px !important;
    border: 0;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.panelContainer {
    z-index: 9999;
    top: 10px;
    position: fixed;
}

.bettAppBar {
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(2, 11, 30, 0.9);
    color: white;
    position: absolute;
    width: auto;
    height: 60px;
    left: 15px;
    right: 15px;

}

.noBorder {
    border: hidden !important;
}

.appBarLogo img {
    margin: auto !important;
}

.appBarElement {
    border-right: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    font-family: 'Poppins' !important;

}

.appBarButton img {
    max-width: 80%;
    max-height: 80%;
}

.appBarElement button {

    max-width: 100%;
    max-height: 100%;
}

.app_bar_active:after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #3b68f8;
    -webkit-transform: translate(16px, 3px);
    -moz-transform: translate(16px, 3px);
    -ms-transform: translate(16px, 3px);
    -o-transform: translate(16px, 3px);
    transform: translate(16px, 3px);
}

.appBarElement span {
    color: #3b68f8;
}

.appBarElement.sliderElement span {
    margin: 10px;
    color: white;
}

.appBarElement.mapDataElement span {
    color: white;
    font-size: 16px;
    align-content: center;
    display: inline-block;
}

.appBarElement.mapDataElement {
    display: inline-flex;
    justify-content: center;
}

.title {
    color: #3b68f8 !important;
    font-size: 12px !important;
}

/*** SENSOR FORM **/
.sensorForm span {
    margin-top: 3px;
    position: relative;
    display: inline-block;
}

.sensorForm {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: white;

}

.sensorForm button {
    min-width: 70px !important;
    font-size: 13px;
    margin: 5px;
}

.adminSensorForm input {
    color: white !important;
}

.sensorId {
    color: #3b68f8;
    font-size: 19px;
    font-weight: bold;

}

.zoomController {
    z-index: 9999;
    position: fixed;
    bottom: 20px;
    right: 15px;
    display: block;

}

.LayerSelector {
    z-index: 9999;
    position: fixed;
    bottom: 120px;
    right: 15px;
    display: block;
    background-color: #020b1e;
    opacity: 0.9;
    border-radius: 5px;

}

.Layerlist {
    color: #ffffff !important;
}

.Layerlist ._12FqV {
    color: #ffffff !important;
}

.zoomButton {
    margin: 10px;
    border-radius: 5px;
    width: 38px;
    height: 38px;
    background-color: #020b1e;
    opacity: 0.9;

}

.zoomButton button {
    width: 100%;
}

.sensorAQIValue {
    color: #3b68f8;

}

.sensorInfo {
    font-size: 16px;
    margin-top: 20px;
}

.sensorForm.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sensorForm.buttons button {
    margin: 5px;

}

.highcharts-container, .highcharts-container svg {
    width: 100% !important;
    height: 100% !important;

}

.sensorForm input {
    text-align: center;
}

.sensorForm input:disabled {
    color: black;
    border-bottom: 1px solid #0A2A59;
}

.sensorForm button:hover {
    font-weight: bold;
}

.imageContainer {
    float: right;
}

.gradientContainer {
    position: relative;
    width: 100%;
    margin-top: 10px;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;

}

.gradientContainer span {
    font-size: 12px;
    max-width: 5%;
    margin: 5px;
    width: auto !important;
    color: rgba(255, 255, 255, 0.70) !important;
}

.gradientContainer img {
    max-height: 15px;
    max-width: 80%;
    margin-top: 5px;
}

.mapDataElementDiv {
    width: 40%;

}

.gradientPanel span {
    width: 100%;
    color: rgba(255, 255, 255, 0.85);
}

.gradientPanel {
    z-index: 99999;
    position: fixed;
    left: 15px;
    bottom: 30px;
    height: 80px;
    color: rgba(255, 255, 255, 0.85);
    padding: 5px;
    border-radius: 10px;
    background-color: rgba(2, 11, 30, 0.9);
    font-family: 'Poppins' !important;
    display: inline-block;
    font-size: 14px;

}

/* Modal DashboardContent/Box */
.modal-content {

    z-index: 99999;
    position: absolute;
    left: 5%;
    bottom: 20px;
    /* left: 5%; */
    /* bottom: 20px; */
    height: 80px;
    color: rgba(255, 255, 255, 0.85);
    padding: 5px;
    border-radius: 10px;
    background-color: rgba(2, 11, 30, 0.9);
    font-family: 'Poppins' !important;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    text-align: center;
    border: hidden;
    width: 500px;
}

.popupRadioGroup {
    font-size: 14px;
    margin-right: 20px;
    margin-top: 7px;

}

.popupRadioButton {
    max-width: 100%;
    /* margin-bottom: 5px; */
    font-weight: 700;
    display: inline-flex;
}

.test {
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
}

.test button {
    background-color: white;
    opacity: 0.95;
    border: 2px solid #3b68f8 !important;
}

.tab {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 120px;

}

.leaflet-zoom-animatedheatmap, .playButton, .slider {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.paddingLeft5px {
    padding-left: 5px;
}

body {
    overflow: hidden;
    width: 100%;
}

.loading {
    z-index: 9999999;
    background-color: #f7f7f77a;
    position: absolute;
    height: 100%;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 40px;
}

.centeredContent {

    position: relative;
    left: 45%;
    width: auto;
    display: inline-flex;
}

.sensorMarker, .shadow {
    width: 15px;
    height: 15px;
    background: radial-gradient(circle at 100px 100px, #5cabff, #000);
    border-radius: 50%;
}

.mapData h2 {
    font-weight: bold;
    margin-left: 10px;
}

.rc-slider-handle {

    border: solid 2px #3b68f8 !important;
    background: #3b68f8 !important;

}

.rc-slider-rail {
    background: black !important;
}

.rc-slider-disabled {
    background-color: transparent !important;
}

.aqiMarker {
    width: 60px;
    height: 60px;
    -webkit-transform: translate(-24px, -66px);
    -moz-transform: translate(-24px, -66px);
    -ms-transform: translate(-24px, -66px);
    -o-transform: translate(-24px, -66px);
    transform: translate(-24px, -66px);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    opacity: 0.9;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #020b1e;

}

.aqiMarker::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    bottom: -10px;
    left: 5px;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 35px solid #020b1e;

}

.panelLogo {
    min-width: 300px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 30px;
}

/************************* OLD ADMIN THINGS  **********/

.bettAdminPanel {
    margin: 0;
    margin-top: 5px;
    z-index: 9999;
    border: 0;
    padding: 0;
    background-color: rgba(10, 42, 89, 0.89);
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bettAdminPanel img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 15px;
}

.bettAdminPanel button {
    background-color: rgba(10, 42, 89, 0.85) !important;
    color: white !important;
    border-color: white !important;
}

.panelElement {
    display: flex;
    flex-flow: column nowrap;

}

.panelElement.sliderPanel {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin-bottom: 7px;
    margin-top: 7px;
}

.panelElement.adminPanel button:hover:not(:disabled) {
    background-color: rgba(10, 42, 89, 0.85);
    color: white !important;
}

.panelElement.adminPanel button:disabled {
    background-color: #0a1c37 !important;
    color: #424242 !important;
}

.loading-image {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    -webkit-animation: loading-spin 1s linear infinite;
    -ms-animation: loading-spin 1s linear infinite;
    -moz-animation: loading-spin 1s linear infinite;
    animation: loading-spin 1s linear infinite;
}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.row {
    padding: 0;
    margin: 0;
}

.noPadding {
    padding: 0px;
}

.mobilePanel {
    text-align: center;
    display: flex;
    flex-flow: column;
    background-color: white;
    bottom: 0;
    margin: 0px;
    z-index: 9999;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 10px;
    border: 0px;
    position: fixed;
    -webkit-box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.19), 0 -6px 6px -6px rgba(0, 0, 0, 0.23);
    box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.19), 0 -6px 10px -6px rgba(0, 0, 0, 0.23);
}

.leaflet-div-icon {
    background-color: transparent;
    border: none;
}

.sensor {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #3B68F8;
    border-radius: 50%;
    transition: all ease-out 400ms;
}

.sensor.iot {
    background-color: #0A2A59;

}

.sensor:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.sensor::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #0A2A59;
    opacity: 0.7;
    display: block;
    position: absolute;
    z-index: -1;
}

.sensor:hover::after {
    animation: sensorHover 1000ms infinite;
}

.leaflet-popup-content-wrapper {
    background-color: rgba(2, 11, 30, 0.9);
    text-align: center;
    width: 200px;
    position: relative;

}

.leaflet-popup-tip {
    background-color: rgba(2, 11, 30, 0.9);
}

@keyframes loading-spin {
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes loading-spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes loading-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-ms-keyframes loading-spin {
    100% {
        -o-transform: rotate(360deg);
    }
}

@media (max-width: 801px) {

    .loading {
        font-size: 20px;
    }

    .panelContainer {
        top: 15px !important;
    }

    .graphPanelContainer {
        top: 110px !important;
    }

    .modal-content {
        font-size: 12px;
    }

    .leaflet-control-container {
        display: none;
    }

    .appBarElement.mapDataElement span {
        font-size: 14px !important;
    }

    .title {
        font-size: 9px !important;
    }

    .gradientContainer span {
        font-size: 9px;
    }

    .gradientPanel {
        font-size: 11px;
        height: 60px;
    }
}

/*Animations*/

@keyframes sensorHover {
    from {
        opacity: 0.7;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(4);
        -moz-transform: scale(4);
        -ms-transform: scale(4);
        -o-transform: scale(4);
        transform: scale(4);
    }
}


/* OWN */

/*@import '~loaders.css/src/animations/line-scale.scss';*/

/*.loader-hidden {*/
/*display: none;*/
/*}*/
/*.loader-active {*/
/*display: block;*/
/*}*/