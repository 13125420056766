.warningMessage {
    color: red
}

.loginLogo {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 30px;
}

.specs-description {
    vertical-align: middle;
    font-size: 0.8em;
    padding-left: 10px;
}

.specs-description p {
    text-align: justify;
    position: relative;
    top: 16px;
}

.europeanInfo {
    display: flex;
    vertical-align: center;
    justify-content: center;
    position: relative;
    top: 25%;
    color: #000000;
}

.europeanInfo p {
    color: #555555;
    text-align: center;
    margin-top: -7px;
}

.europeanFlag {
    margin-top: 9px;
}
