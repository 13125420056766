#bettair-frontend {
    width: 100%;
    height: 100%;
    position: absolute;
}

.display-table {
    display: table;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #efefef;
}

.nodeListCheckBox div {
    margin-top: 7px;
}

.display-table-row {
    display: table-row;
    height: 100%;
}

#dashboardPage {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background-color: #efefef;
    left: 0;
    top: 0;
}

.no-padding {
    padding: 0;
}

body {
    overflow-y: scroll !important;
}

.iconContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.variableHeight {
    height: 100%;
}

.dashboardContainer {
    overflow: hidden;
    padding: 15px;
    padding-left: 330px;
}

.paper {
    margin-bottom: 30px;
}

.mainContainer {
    flexGrow: 1,
}

.top-right-button {
    position: absolute;
    top: 50px;
    right: 30px;
}

.progressDiv {
    width: 100%;
    text-align: center;
    margin: 10px;
}

.textfieldFullWidth {
    width: 100%;
}

.timeKo {
    color: lightcoral;
    font-weight: bold;
}

.timeOk {
    color: lightgreen;
    font-weight: bold;
}

.timeWarn {
    color: #f7c223;
    font-weight: bold;
}


.usersHeader {
    text-align: left;
    color: #0a2a59;
    font-weight: bold;
}