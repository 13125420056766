.userInfoContainer {
    height: 100%;
    margin-top: 30px;
}

.minus10PxMargin {
    margin: -10px;
}

.ViewAccountHeader {
    font-family: Roboto, serif;
    color: #0a1c37;
    margin: 20px;
}

.userInfoContent {
    width: 90%;
    margin: 10% auto;
}

.userInput > input {
    border-bottom: 1px solid #4a6480;
    border-bottom-style: solid !important;
    color: whitesmoke !important;

}

.userInput > label {
    color: whitesmoke !important;
}

.submitUserEdit {
    margin: 0 40%;
    opacity: 1;
    animation: fade 0.7s linear;
}

@keyframes fade {
    0% {
        opacity: 0
    }
    50% {
        opacity: 0.60
    }
    100% {
        opacity: 1
    }
}

.userInput span::after {
    background-color: whitesmoke !important;
}

.userInput span:before {
    background-color: whitesmoke !important;
}

.profileColumn {
    /*height: 100%;*/
    min-height: 100%;
    margin: 0;
    padding: 0;
}

.userInfoData {
    margin: 15px;
    padding: 20px 15px;
}

.editIcon {
    float: right;
    z-index: 99;
    color: #426484 !important;
    background-color: #EDF1F5 !important;
    margin: 5px 20px 10px 10px;
}

.left {
    color: white;
    background-color: rgba(15, 69, 118, 0.44);
    /*background-color: #white;*/
}

.right {
    color: white;
    background-color: rgba(15, 56, 98, 0.59);
}

.shapedDiv {
    height: 29px;
    width: 140px;
    padding: 4px;
    margin-top: -28px;
    -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 75% 100%, 0% 100%);
    clip-path: polygon(0 0, 75% 0, 100% 100%, 75% 100%, 0% 100%);
    -webkit-transition: width 1s; /* Safari */
    transition: width 1s;

}

.shapedDiv h4 {
    color: white;
    padding: 5px;
    margin: 0;
}

.left .shapedDiv {
    background-color: #99AEC2;
}

.right .shapedDiv {
    background-color: #768BA2;
}

.profileColumn:hover .shapedDiv {
    font-weight: bold;
    width: 180px;
}

.profileColumn:hover .shapedDiv h4 {
    text-shadow: 0 0 .5px white, 0 0 .5px white;

}

/** ORGANIZATION SIDE **/
.organizationData {
    display: inline-flex;

    width: 100%;
    border-bottom: 1px solid black;
}

.viewOrganizationButton {
    color: white !important;
    float: right;
    width: 40px;
    margin-top: 30px;
    margin-right: 20px;
}

.orgInfo {

    display: block;
    width: 100%;
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.orgInfo h4 {
    font-family: Roboto, serif;

    font-weight: bold;
    font-size: 15px;
}

.orgInfo h3 {
    font-family: Roboto, serif;

    maring-top: 15px;
    font-size: 19px;
}

.organizationData:hover {
    cursor: pointer;
    background-color: rgba(10, 24, 48, 0.34);

    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color color 0.2s linear;
    transition: background-color 0.2s linear;
}

.organizationData .activeOrg {
    background-color: rgba(246, 246, 246, 0.68) !important;
}

.organizationData:hover .viewOrganizationButton {

    background-color: rgba(246, 246, 246, 0.68);
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color color 0.2s linear;
    transition: background-color 0.2s linear;

    color: #0a1c37 !important;

}

.viewOrganizationButton:hover {

    -webkit-animation: pulse 2s cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 2s cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 2s cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 2s cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0px rgba(246, 246, 246, 0.68);
    }
    70% {
        -webkit-box-shadow: 0 0 0 5px rgba(246, 246, 246, 0.29);
    }
    100% {
        -webkit-box-shadow: 0 0 0 2px rgba(246, 246, 246, 0.68);
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0px rgba(246, 246, 246, 0.68);
    }
    70% {
        -webkit-box-shadow: 0 0 0 5px rgba(246, 246, 246, 0.29);
    }
    100% {
        -webkit-box-shadow: 0 0 0 2px rgba(246, 246, 246, 0.68);
    }
}
