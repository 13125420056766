.nodeStockChart {
    background-color: #FAFAFA;
    margin-bottom: 20px;
    padding: 15px;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

}

.loadChart {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 150px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 440px;
    background-color: white;
}

.margin20px {
    margin: 20px;
}

.noPadding {
    padding: 0px;
}

.center {
    margin: auto;
    width: 50%;
    padding: 10px 55px;
}

.csvLink {
    margin-right: 15px;
    color: #3f51b5;
}

.divLoaderChart {
    position: relative;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}