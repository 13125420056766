.addUserFormContainer {
    text-align: center;
    border-radius: 10px;
    margin: 30px 15px;
    background-color: white;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.addUserForm{
    padding: 70px 20px;
}

.content {
    align-items: center;
    height: 50%;
    display: flex;
    justify-content: center;
}

.inputForm {
    margin: 5px 30px !important;
}

.formHeader {
    font-family: 'Roboto';
    font-weight: bold;
    color: white;
    font-size: 25px;
    /* margin-top: -25px; */
    border-radius: 5px;
    float: left;
    padding: 15px 50px 15px 25px;
    margin: -40px -20px -20px -20px;
    width: 20%;
    background-color: #535b96;
    text-align: left;
}

.addUserFormContainer .formHeader {
    margin: -20px;
}
